import { render, staticRenderFns } from "./TableControl.vue?vue&type=template&id=66b3a467&scoped=true&"
import script from "./TableControl.vue?vue&type=script&lang=js&"
export * from "./TableControl.vue?vue&type=script&lang=js&"
import style0 from "./TableControl.vue?vue&type=style&index=0&id=66b3a467&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66b3a467",
  null
  
)

export default component.exports